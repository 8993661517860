import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export const accommodation = [
  { text: $gettext('Renting'), value: 'rental' },
  { text: $gettext('Owner-occupied house'), value: 'own' },
  { text: $gettext('Partowner-occupied house'), value: 'partialOwnership' },
  { text: $gettext('With parents or acquaintances'), value: 'parentOrRelative' },
  { text: $gettext('Other'), value: 'other' },
];

export const accommodationType = [
  { text: $gettext('Town house'), value: 'ownHouse' },
  { text: $gettext('Terraced house'), value: 'rowHouse' },
  { text: $gettext('Apartment house'), value: 'appartment' },
  { text: $gettext('Other'), value: 'other' },
];

export const education = [
  { text: $gettext('Grammar school'), value: 'grammar' },
  { text: $gettext('Primary school'), value: 'elementary' },
  { text: $gettext('Trade School or course'), value: 'careerCollege' },
  { text: $gettext('High School'), value: 'highSchool' },
  { text: $gettext('College vocational training'), value: 'academy' },
  { text: $gettext('University of Applied Sciences'), value: 'polytechnic' },
  { text: $gettext('College, lower degree'), value: 'lowerUniversityDegree' },
  { text: $gettext('College, postgraduate degree'), value: 'higherUniversityDegree' },
];

export const income = [
  { text: $gettext('Under 500 €'), value: '0-50000' },
  { text: '501 - 1 000 €', value: '50100-100000' },
  { text: '1 001 - 1 500 €', value: '100100-150000' },
  { text: '1 501 - 2 000 €', value: '150100-200000' },
  { text: '2 001 - 2 500 €', value: '200100-250000' },
  { text: '2 501 - 3 000 €', value: '250100-300000' },
  { text: '3 001 - 3 500 €', value: '300100-350000' },
  { text: '3 501 - 4 000 €', value: '350100-400000' },
  { text: '4 001 - 4 500 €', value: '400100-450000' },
  { text: '4 501 - 5 000 €', value: '450100-500000' },
  { text: $gettext('Over 5 000 €'), value: '500000+' },
];

export const incomeTypes = [
  { label: $gettext('Salary'), name: 'salary' },
  { label: $gettext('Student grant'), name: 'studentAllowance' },
  { label: $gettext('Social Benefits'), name: 'monthlyIncomeWelfare' },
  { label: $gettext('Rental Support'), name: 'rentSupport' },
  { label: $gettext('Pension'), name: 'pension' },
  { label: $gettext('Unemployment benefit'), name: 'unemploymentAid' },
  { label: $gettext('Other income'), name: 'monthlyIncomeOther' },
];

export const openLoanTypes = [
  { label: $gettext('Mortgage'), name: 'mortgage' },
  { label: $gettext('Student loan'), name: 'studentLoan' },
  { label: $gettext('Credit card loan'), name: 'creditCardLoan' },
  { label: $gettext('Car loan'), name: 'carLoan' },
  { label: $gettext('Consumer credit'), name: 'consumerLoan' },
  { label: $gettext('Payday loan'), name: 'paydayLoan' },
  { label: $gettext('Other'), name: 'otherLoan' },
];

export const purpose = [
  { text: $gettext('Drivers Licence'), value: 'driversLicence' },
  { text: $gettext('Car / boat / motorcycle'), value: 'vehicle' },
  { text: $gettext('Wedding'), value: 'wedding' },
  { text: $gettext('Household Purchases'), value: 'householdExpenses' },
  { text: $gettext('Consumption'), value: 'consumption' },
  { text: $gettext('Loan consolidation'), value: 'mergingOfLoans' },
  { text: $gettext('Pet'), value: 'pet' },
  { text: $gettext('Doctor'), value: 'doctor' },
  { text: $gettext('Travelling'), value: 'travel' },
  { text: $gettext('Other Household expenses'), value: 'additionalLivingExpenses' },
  { text: $gettext('Studying'), value: 'study' },
  { text: $gettext('Home Decoration'), value: 'renovation' },
  { text: $gettext('Taxes'), value: 'taxes' },
  { text: $gettext('Corporate loan'), value: 'businessLoan' },
  { text: $gettext('Something else'), value: 'other' },
];

export const sizeOfFamily = [
  { text: $gettext('1 adult'), value: '1-0' },
  { text: $gettext('1 adult and 1 child'), value: '1-1' },
  { text: $gettext('1 adult and 2 children'), value: '1-2' },
  { text: $gettext('1 adult and 3 or more children'), value: '1-3+' },
  { text: $gettext('2 adults'), value: '2-0' },
  { text: $gettext('2 adults and 1 child'), value: '2-1' },
  { text: $gettext('2 adults and 2 children'), value: '2-2' },
  { text: $gettext('2 adults and 3 or more children'), value: '2-3+' },
  { text: $gettext('Other'), value: 'other' },
];

export const workRelationshipType = [
  { text: $gettext('Employee'), value: 'employee' },
  { text: $gettext('Part time'), value: 'parttime' },
  { text: $gettext('Lower degree clerk'), value: 'lowerDegreeOfficial' },
  { text: $gettext('Senior clerk or managerial position'), value: 'higherDegreeOfficial' },
];

export const workSituation = [
  { text: $gettext('Permanent'), value: 'steady' },
  { text: $gettext('Temporary'), value: 'fixed-period' },
  { text: $gettext('Entrepreneur'), value: 'entrepreneur' },
  { text: $gettext('Student'), value: 'student' },
  { text: $gettext('Retired'), value: 'retired' },
  { text: $gettext('Early retirement'), value: 'earlyRetirement' },
  { text: $gettext('Unemployed'), value: 'unemployed' },
  { text: $gettext('Other'), value: 'other' },
];
